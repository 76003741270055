.red-circle {
  color: white; /* Adjust the styles accordingly */
  background-color: #AA0000;
  border-radius: 50%;
}

.green-circle {
  color: white; /* Adjust the styles accordingly */
  background-color: #03C03C;
border-radius: 50%;

}

.grey-circle {
   color: white; /* Adjust the styles accordingly */
  background-color: grey;
    border-radius: 50%;

}