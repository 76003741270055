*{
    margin: 0;
    padding: 0;
  }
  body{
    background-color: #333;
  }
  .main-container{
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    height: 85vh;
    width: 65vh;
  }
  .center-container{
    /* height: 290px; */
    width: fit-content;
  }
  .app-heading{
    padding: 4px;
    color: white;  
    /* text-align: center; */
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-transform: uppercase;
  }
  .input-container{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .input-box-todo{

    margin-bottom: 2px;
    margin-right: 20px;
    width: 70%;
    height: 40px;
    border-radius: 5px;
    border:1px solid rgb(161, 158, 158);;
    padding-left: 10px;  
    /* box-shadow: 0px 10px 20px rgba(0,0,0,0.3); */
    outline: none;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: large;
  }
  .add-btn{
    width:40px;
    height:40px;
    border-radius: 50%;
    border:none;
    background-color:#316fc1;
    color: white;
    font-weight: bolder;
    font-size: larger;
    margin-left: 10px;  
    cursor: pointer;
    box-shadow: 0px 5px 10px rgba(11, 10, 10, 0.3);
    transition: 0.3s;
  }
  .add-btn:active{
    box-shadow: none;
  }
  .list-container{
    display: flex;
    align-items: center;
    margin-top: 5px;
    /* background-color: #316fc1; */
  }
  .list-item {
    list-style-type: none;
    /* background-color: #596e8b; */
    border: 2px solid rgb(161, 158, 158);
    /* color: white; */
    padding: 3px;
    padding-left: 5px;
    border-radius: 5px;
    
    width: 60%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 10px;
    /* background-color: #316fc1; */
    color: black;
    min-height: 20px;
  }
  
  .list-item-content {
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden; /* Hides any overflowing text */
    text-overflow: ellipsis; /* Displays an ellipsis (...) when text overflows */
    max-width: 100%; /* Set the maximum width to prevent overflow */
  }
  
  .icons{
    position: absolute;
    right: 10px;
  }
  .icons i{
    margin: 4px;
    font-size: large;
    cursor: pointer;
    transition: 0.1s;   
  }
  .icons i:hover{
    transform: scale(1.2);  
  }
  .icon-delete:hover{
    color: red;
  }


  /* Pagination section  */

  .pagination-button-number:hover{
    background-color: black;
    box-sizing: border-box;
    color: white;
    padding: 0 0.5rem;
    border-radius: 50%;
  }

  .pagination-button:hover{
    background-color: black;
    color: white;
    padding: 0 1rem;
    border-radius: 2rem;
  }

/*  */