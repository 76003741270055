
  .spinner {
     width: 56px;
     height: 56px;
     border-radius: 50%;
     border: 9px solid;
     border-color: #dbdcef;
     border-right-color: #000000;
     animation: spinner-d3wgkg 1s infinite linear;
  }
  .MainDiv{
    height: 100vh;
    width: 90%;
    position: absolute;
    overflow-x: scroll;
  }
  @keyframes spinner-d3wgkg {
     to {
        transform: rotate(1turn);
     }
  }

/* 
  .card {
    background: #FFFFFF;
    display: inline-block;
    height: 320px;
    width: 27vw;
   
  } */
  
  .card-1 {
    position: relative;
    top: 65px;
    border-radius: 2rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }
  
  .card-1:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
/* 

  .ProjectDetailsCard{  
    margin-left: 2rem;
    margin-top: 1.4rem;
  } */

  .ant-modal-content {
    position: relative;
    background-color: #cc1313;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 20px 24px;
  }

