.add-group-button {
    margin-right: 2px;
    background-color: #4E79FA !important;
    color: #FFFFFF !important;
    height: 56px;
}

.action-button {
  color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 1rem;
  box-shadow: none;
  border: none;
}