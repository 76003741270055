html {
    font-size: 16px;
}

body {
    margin: 0px;
    padding: 0px;
    font-family: 'Kanit', sans-serif;
}

* {
    padding: 0;
    margin: 0;
}
